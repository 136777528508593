/*body*/
.bsgpsK div:first-child {
    overflow: visible !important;
    white-space: normal !important;
    text-align: center;
}

/*heading*/
.lnndaO {
    overflow-wrap: break-word !important;
    white-space: normal !important;
    text-align: center;
}

.sc-dlVxhl .hTfvHt {
    min-height: 500px !important;
}
