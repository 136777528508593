@import url('antd/dist/antd.min.css');
@import url('react-toastify/dist/ReactToastify.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'FF Clan';
    src: local('FF Clan News'), local('FF-Clan-News'),
        url('./assets/fonts/ClanOT-News.woff2') format('woff2'),
        url('./assets/fonts/ClanOT-News.woff') format('woff'),
        url('./assets/fonts/ClanOT-News.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

:root {
    --primary: rgb(185, 55, 61);
}
body {
    margin: 0;
    padding: 0;
    font-family: 'FF Clan', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #a0aec0;
    border-radius: 100px;
    background-clip: padding-box;
    height: 10px;
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
    -webkit-appearance: none;
}

.ant-checkbox-inner {
    height: 25px;
    width: 25px;
    display: grid;
    place-content: center;
    place-items: center;
    align-items: center;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #a0aec0;
}

.link {
    @apply text-red-700 underline;
}

/* Antd customize */
.ant-select-selector {
    border-radius: 0.5rem !important;
}

.ant-collapse {
    @apply border-0 bg-white;
}

.ant-collapse-item,
.ant-collapse-item:last-child {
    @apply bg-gray my-4 rounded-md;
}

.ant-collapse-item:last-child {
    border-radius: 0.375rem !important;
}

.ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 8px 16px;
}

.container1 {
    width: 200px;
    margin: 50px auto;
}

.svg-success {
    display: inline-block;
    vertical-align: top;
    height: 200px;
    width: 200px;
    opacity: 1;
    overflow: visible;
}

@-webkit-keyframes success-tick {
    0% {
        stroke-dashoffset: 16px;
        opacity: 1;
    }
    100% {
        stroke-dashoffset: 31px;
        opacity: 1;
    }
}
@keyframes success-tick {
    0% {
        stroke-dashoffset: 16px;
        opacity: 1;
    }
    100% {
        stroke-dashoffset: 31px;
        opacity: 1;
    }
}
@-webkit-keyframes success-circle-outline {
    0% {
        stroke-dashoffset: 72px;
        opacity: 1;
    }
    100% {
        stroke-dashoffset: 0px;
        opacity: 1;
    }
}
@keyframes success-circle-outline {
    0% {
        stroke-dashoffset: 72px;
        opacity: 1;
    }
    100% {
        stroke-dashoffset: 0px;
        opacity: 1;
    }
}
@-webkit-keyframes success-circle-fill {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes success-circle-fill {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.svg-success .success-tick {
    fill: none;
    stroke-width: 1px;
    stroke: #ffffff;
    stroke-dasharray: 15px, 15px;
    stroke-dashoffset: -14px;
    -webkit-animation: success-tick 450ms ease 1400ms forwards;
    animation: success-tick 450ms ease 1400ms forwards;
    opacity: 0;
}
.svg-success .success-circle-outline {
    fill: none;
    stroke-width: 1px;
    stroke: #81c038;
    stroke-dasharray: 72px, 72px;
    stroke-dashoffset: 72px;
    -webkit-animation: success-circle-outline 300ms ease-in-out 800ms forwards;
    animation: success-circle-outline 300ms ease-in-out 800ms forwards;
    opacity: 0;
}
.svg-success .success-circle-fill {
    fill: #81c038;
    stroke: none;
    opacity: 0;
    -webkit-animation: success-circle-fill 300ms ease-out 1100ms forwards;
    animation: success-circle-fill 300ms ease-out 1100ms forwards;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: 600;
}

.ant-radio-checked .ant-radio-inner:focus {
    border-color: var(--primary);
}

.ant-radio-checked .ant-radio-inner {
    border-color: var(--primary) !important ;
}

.ant-radio-checked .ant-radio-inner:after {
    background-color: var(--primary);
}

.ant-radio:hover .ant-radio-inner {
    border-color: var(--primary);
}

.search .rc-virtual-list .rc-virtual-list-scrollbar {
    display: block !important;
}

img {
    width: 100%;
    height: 100%;
}

.Toastify .Toastify__toast-container {
    z-index: 9999999999999999;
    position: absolute;
}
